import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import querystring from 'querystring';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder, { BlockListing } from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionX2SectionsDynamicListings.module.css';
import Spinner from '../../../../components/IconSpinner/IconSpinner';
import { useListListingsQuery } from '../../../../ducks/ListingsApi';

// The number of columns (numColumns) affects styling and responsive images
const COLUMN_CONFIG = [
  { css: css.oneColumn, responsiveImageSizes: '(max-width: 767px) 100vw, 1200px' },
  { css: css.twoColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 600px' },
  { css: css.threeColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 400px' },
  { css: css.fourColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 265px' },
];
const getIndex = numColumns => numColumns - 1;
const getColumnCSS = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.css : COLUMN_CONFIG[0].css;
};
const getResponsiveImageSizes = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.responsiveImageSizes : COLUMN_CONFIG[0].responsiveImageSizes;
};

const STILL_INTERESTED_SECTION_ID = 'still-interested-listings';
const FAVORITE_LISTINGS_SECTION_ID = 'your-favorite-listings';

const getRecentlyVisitedListingIds = user =>
  user?.attributes?.profile?.protectedData?.recentlyVisitedListings || [];

const getFavoriteListingIds = user => user?.attributes?.profile?.publicData?.favoriteListings || [];

const getQueryParams = props => {
  const { currentUser, block } = props;
  const parsedContent = querystring.parse(block.text.content, null, null, {
    decodeURIComponent: decodeURIComponent,
  });
  let ids;

  switch (block.blockId) {
    case STILL_INTERESTED_SECTION_ID:
      ids = getRecentlyVisitedListingIds(currentUser);
      break;
    case FAVORITE_LISTINGS_SECTION_ID:
      ids = getFavoriteListingIds(currentUser);
      break;
    default:
      break;
  }

  return {
    ...parsedContent,
    ...(ids && ids.length > 0 ? { ids } : {}),
  };
};

const Block = props => {
  const { sectionId, defaultClasses, numColumns, options, block } = props;
  // eslint-disable-next-line no-unused-vars
  const { data, isLoading, error } = useListListingsQuery(getQueryParams(props));
  const listingsRaw = data?.listings || [];

  const listings =
    listingsRaw &&
    listingsRaw.map(listing => ({
      blockType: 'defaultBlock',
      blockId: listing?.id?.uuid,
      listing,
    }));

  return (
    <div key={block.id || block.blockName} className={css.listContainer}>
      <Field data={block.title} />
      {!isLoading ? (
        <div
          className={classNames(defaultClasses.blockContainer, getColumnCSS(numColumns), {
            [css.noSidePaddings]: true,
          })}
        >
          <BlockBuilder
            rootClassName={css.block}
            ctaButtonClass={defaultClasses.ctaButton}
            blocks={listings}
            sectionId={sectionId}
            responsiveImageSizes={getResponsiveImageSizes(numColumns)}
            options={{
              ...options,
              blockComponents: {
                defaultBlock: {
                  component: BlockListing,
                },
              },
            }}
          />
        </div>
      ) : (
        <div>
          <Spinner />
        </div>
      )}
    </div>
  );
};

// Section component that's able to show blocks in multiple different columns (defined by "numColumns" prop)
const SectionX2SectionsDynamicListings = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;

  return (
    <SectionContainer
      id={sectionId}
      className={classNames(className, css.root)}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
      {hasBlocks ? (
        <div className={classNames(defaultClasses.blockContainer, css.containers)}>
          {blocks.map(block => (
            <Block {...props} key={block.blockId} block={block} />
          ))}
        </div>
      ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionX2SectionsDynamicListings.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  numColumns: 1,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionX2SectionsDynamicListings.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  numColumns: number,
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SectionX2SectionsDynamicListings);
