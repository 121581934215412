import { bool, func, node, object, shape, string } from 'prop-types';
import React from 'react';

import SectionCategories from './SectionCategories';
import SectionColumnsDefault from './SectionColumnsDefault';
import SectionX2SectionsDynamicListings from './SectionX2SectionsDynamicListings';
import SectionBanners from './SectionBanners';
import SectionColumnsStyle1 from './SectionColumnsStyle1';
import Section5Columns from './Section5Columns';
import SectionBrandLogos from './SectionBrandLogos';

// Section component that's able to show blocks in multiple different columns (defined by "numColumns" prop)
const SectionColumnsCustom = props => {
  const { sectionName } = props;

  if (/^brand-logos/.test(sectionName)) {
    return <SectionBrandLogos {...props} />;
  }

  if (/^5-columns/.test(sectionName)) {
    return <Section5Columns {...props} />;
  }

  if (/^columns-style1/.test(sectionName)) {
    return <SectionColumnsStyle1 {...props} />;
  }

  if (/^banners-section/.test(sectionName)) {
    return <SectionBanners {...props} />;
  }

  if (/^x2-sections-dynamic-listings/.test(sectionName)) {
    return <SectionX2SectionsDynamicListings {...props} />;
  }

  if (/^categories-logo/.test(sectionName)) {
    return <SectionCategories {...props} />;
  }

  return <SectionColumnsDefault {...props} />;
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionColumnsCustom.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionColumnsCustom.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionColumnsCustom;
