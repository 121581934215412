import classNames from 'classnames';
import { func, node, object, shape, string } from 'prop-types';
import React from 'react';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import { NamedLink } from '../../../../components';
import css from './BlockCategoryLogo.module.css';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockCategoryLogo = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
    blockName,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  const propertiesStr = blockName.split('--')?.[1] || '';
  const propertiesPairs = propertiesStr.split('-') || [];
  const styles = propertiesPairs.reduce((acc, curr = '') => {
    const property = curr.split('_');
    acc[property[0]] = property[1];
    return acc;
  }, {});

  return (
    <BlockContainer id={blockId} className={classes} style={styles}>
      <NamedLink name="SearchPage" to={{ search: callToAction?.href }}>
        <FieldMedia
          media={media}
          sizes={responsiveImageSizes}
          className={mediaClassName}
          options={options}
        />
        {hasTextComponentFields ? (
          <div className={classNames(textClassName, css.text)}>
            <Field data={title} options={options} />
            <Field data={text} options={options} />
          </div>
        ) : null}
      </NamedLink>
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockCategoryLogo.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockCategoryLogo.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockCategoryLogo;
